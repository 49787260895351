
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase } from "@/core/api";
import { useI18n } from "vue-i18n";
import { getExportDeclarationStatusOptions } from "@/core/directive/function/exportDeclaration";

interface Filter {
  status: string;
  declaration_export_no: string;
  tracking_no: string;
  // taggings: Array<string>;
}

export default defineComponent({
  name: "filter-warehouses-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      status: "",
      declaration_export_no: "",
      tracking_no: "",
    });

    const options = ref({
      status: getExportDeclarationStatusOptions(t),
      service_provider_based_country: [],
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    const getOptions = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.service_provider_based_country = data.data;
      }
    };

    onMounted(() => {
      getOptions();
    });

    return {
      t,
      formData,
      formRef,
      options,
      submit,
      handleReset,
      getOptions,
    };
  },
});


import { defineComponent, ref, onMounted, onActivated } from "vue";
import { MBDatatable } from "magicbean-saas-common";
// import addExportDeclaration from "@/views/declaration-management/export-declaration/AddExportDeclaration.vue";
import FilterDropdown from "@/views/declaration-management/export-declaration/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiCustomDeclarationExport } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { ExportDeclarationTable } from "@/core/directive/interface/exportDeclarations";

import store from "@/store";
import { getExportDeclarationStatus } from "@/core/directive/function/exportDeclaration";
import fileDownload from "js-file-download";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "declaration-management-export-declaration",
  components: {
    MBDatatable,
    FilterDropdown,
    // addExportDeclaration,
  },
  setup() {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(true);
    const disabledExportReport = ref(false);
    const tableData = ref<Array<ExportDeclarationTable>>([]);
    const checkedImportDeclaration = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const tableHeader = ref([
      // {
      //   name: t("customDeclarationExport.systemId"),
      //   key: "id",
      //   sortable: true,
      // },
      {
        name: t("customDeclarationExport.customsDeclarationNo"),
        key: "declaration_export_no",
        sortable: true,
      },
      {
        name: t("customDeclarationExport.overseasShipment"),
        key: "tracking_no",
        sortable: true,
      },
      {
        name: t("customDeclarationExport.exportHandlingCompanyName"),
        key: "export_handling_company",
        sortable: false,
      },
      {
        name: t("customDeclarationExport.carrierName"),
        key: "carrier_name",
        sortable: true,
      },
      {
        name: t("customDeclarationExport.awbNumber"),
        key: "awb_number",
        sortable: false,
      },
      {
        name: t("customDeclarationExport.mrnNumber"),
        key: "mrn_number",
        sortable: false,
      },
      {
        name: t("customDeclarationExport.declarationDate"),
        key: "declaration_date",
        sortable: false,
      },
      {
        name: t("customDeclarationExport.approvalDate"),
        key: "approval_date",
        sortable: false,
      },
      {
        name: t("customDeclarationExport.customsInspection"),
        key: "customs_inspection",
        sortable: false,
      },
      {
        name: t("customDeclarationExport.exportPort"),
        key: "export_port",
        sortable: false,
      },
      {
        name: t("customDeclarationExport.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getCustomDeclarationExportList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getCustomDeclarationExportList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiCustomDeclarationExport.getCustomDeclarationExportList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            tableData.value.forEach((item) => {
              item.downloadDisabled = false;
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getCustomDeclarationExportList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getCustomDeclarationExportList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getCustomDeclarationExportList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "shipment_id" || item == "status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getCustomDeclarationExportList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getCustomDeclarationExportList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getCustomDeclarationExportList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const statusClass = (status) => {
      return getExportDeclarationStatus(status, t);
    };

    const fileExportReport = () => {
      if (disabledExportReport.value) true;
      disabledExportReport.value = true;
      ApiCustomDeclarationExport.exportReport({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportReport.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              fileDownload(data.data, "Customs Declaration Report.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportReport.value = false;
          console.log(error);
        });
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      checkedImportDeclaration,
      getCustomDeclarationExportList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      fileExportReport,
      disabledExportReport,
    };
  },
});

import { CommonStatus, TaggingItem } from "../interface/common";
import { BadgeStyle, CommonLanguageType, NumberOrString } from "../type/common";
import { ExportDeclarationStatus } from "../type/exportDeclarations";

/**
 * @description: 获取履约状态（文本和样式）
 * @return CommonStatus
 */
export const getExportDeclarationStatus = (
  status: NumberOrString,
  language: CommonLanguageType
): CommonStatus => {
  let statusClass = "",
    str = "";

  switch (status) {
    case ExportDeclarationStatus.New:
      str = language("customDeclarationExport.statusNew");
      statusClass = BadgeStyle.Primary;
      break;
    default:
      statusClass = BadgeStyle.White;
      break;
  }
  return { statusClass, str };
};

/**
 * @description: 获取履约状态
 * @return TaggingItem[]
 */
export const getExportDeclarationStatusOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("customDeclarationExport.statusNew"),
      value: String(ExportDeclarationStatus.New),
    },
    // {
    //   label: language("customDeclarationExport.statusProcessing"),
    //   value: String(ExportDeclarationStatus.Processing),
    // },
  ];
};
